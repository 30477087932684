import React from 'react'
import IndexLayout from '../layouts'
import PrivacySection from '../components/PrivacySection'

const PrivacyPolicy = () => {
  return (
    <IndexLayout
      title="Privacy Policy - Bob's Business"
      heading={'Privacy Policy'}
      body={'Privacy Notice for Our Customers & Website Visitors'}
      currentPage={-5}
    >
      <PrivacySection heading={'Background'}>
        <p>
          We understand that your privacy is important and that you care about how your personal data is used and shared. We respect the
          privacy of everyone who visits our website and uses our online services.
        </p>
        <p>
          We are committed to respecting, securing, and protecting your privacy and private data. We are also committed to being transparent
          about what we collect from you and how we use it.
        </p>
        <p>
          This privacy notice provides you with information about what personal data we collect, how we use your data, how we ensure your
          privacy is maintained, and your legal rights relating to your personal data.
        </p>
      </PrivacySection>
      <PrivacySection heading={'Who We Are'}>
        <p>We are Bobs Business.</p>

        <p>Our registered office address is: Digital Media Centre, County Way, Barnsley, S70 2JW</p>

        <p>You can contact us by:</p>

        <p>
          Telephone – <a href="tel:0330 0583009">0330 0583009</a>
        </p>

        <p>
          Email – <a href="mailto:info@bobsbusiness.co.uk">info@bobsbusiness.co.uk</a>
        </p>

        <p>Post – Bob’s Business, Digital Media Centre, County Way, Barnsley, S70 2JW</p>

        <p>European Union funding disclaimer</p>
      </PrivacySection>
      <PrivacySection heading="Your Rights">
        <p>
          Under the General Data Protection Regulation (GDPR) and The Data Protection Act 2018 (DPA) you have a number of rights with regard
          to your personal data; which this policy and our use of your data has been designed to uphold:
        </p>

        <p>Right to be informed – you have the right to be informed about our collection and use of your personal data.</p>

        <p>
          Right of access – you have the right to request a copy of the information that we hold about you. You can do this by contacting us
          using the above details.
        </p>

        <p>Right of rectification – you have a right to correct data that we hold about you that is inaccurate or incomplete.</p>

        <p>Right to be forgotten – in certain circumstances you can ask for the data we hold about you to be erased from our records.</p>

        <p>Right to restriction of processing – where certain conditions apply, you have a right to restrict our processing.</p>

        <p>Right of portability – you have the right to have the data we hold about you transferred to another organisation.</p>

        <p>Right to object – you have the right to object to certain types of processing (such as direct marketing).</p>

        <p>
          If you have cause for complaint about our use of your data, or you would like to exercise any of your rights, then please contact
          us using the details provided in Section 1 and we will do our best to solve the problem for you.
        </p>

        <p>
          If we are unable to help, or you aren’t satisfied with our response, you also have the right to lodge a complaint with the UK’s
          supervisory authority – The Information Commissioner’s Office (ICO). The ICO can be contacted:
        </p>

        <p>By post – The Information Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, SK9 5AF</p>

        <p>
          By telephone – <a href="tel:0303 123 1113">0303 123 1113</a>
        </p>

        <p>
          Via its website –
          <a href="http://ico.org.uk" target="_blank">
            www.ico.org.uk
          </a>
        </p>
      </PrivacySection>
      <PrivacySection heading="How We Use Your Data">
        <p>
          Data protection law means that we can only use your data for certain reasons and where we have a legal basis to do so. Here are
          the reasons for which we process your data:
        </p>

        <p>
          Orders & Quotes – We’ll use the details you provide to process any orders that you make with us using our websites, apps or over
          the phone. If we don’t collect this information we won’t be able to process your order and comply with our legal obligations. Our
          legal basis for this is contractual obligation.
        </p>

        <p>
          Keeping Our Website Running: Providing and managing your access to our website and services, personalising and tailoring your
          experience on our website and services. Our legal basis for this is legitimate interest (see below).
        </p>

        <p>
          Improving Our Website: Testing features, interacting with feedback platforms and questionnaires, managing landing pages, heat
          mapping our site, traffic optimization and data analysis and research, including profiling and the use of machine learning and
          other techniques over your data and in some cases using third parties to do this. Our legal basis for this is legitimate interest
          (see below).
        </p>

        <p>
          Marketing Purposes: We may send you emails and messages about new features, products and services, and content. You will always be
          able to unsubscribe from these. Our legal basis for doing that is either consent or legitimate interest (see below).
        </p>

        <p>
          Answering Your Queries & Customer Support: We will use your email and contact details to answer your contact requests and queries.
          Our legal basis for doing so is contractual obligation.
        </p>

        <p>
          Preventing Fraud & Crime: We use some data to protect our business and your account from fraud and other illegal activities (for
          example, we use CCTV to prevent crime; and we look for out irregular orders on our websites). Our legal basis for doing so is
          legitimate interests.
        </p>
      </PrivacySection>
      <PrivacySection heading="Legal Basis">
        <p>We have identified a legal basis for each of our purposes in paragraph 4. This is what they mean:</p>

        <p>Legitimate Interest:</p>

        <p>
          Processing your data is necessary for our legitimate interests or the legitimate interests of a third party, provided those
          interests are not outweighed by your rights and interests. These legitimate interests are:
        </p>
        <ul>
          <li>Gaining insights from your behaviour on our website</li>
          <li>Delivering, developing and improving our service</li>
          <li>Enabling us to enhance, customise or modify our services and comms</li>
          <li>Enhancing data and physical security</li>
          <li>Promoting our products, services and business.</li>
          <li>Responding to customer enquiries, contact requests and promoting our services.</li>
        </ul>
        <p>In each case, these legitimate interests are only valid if they are not outweighed by your rights and interests.</p>

        <p>
          You can always object to our processing of your data based on legitimate interest. If you do so and we have no other legal basis
          for processing your data we will stop. If we do have another legal basis we will continue to do so, subject to your legal rights.
        </p>
      </PrivacySection>
      <PrivacySection heading="Consent">
        <p>You have given clear consent for you to process your personal data for a specific purpose.</p>

        <p>
          You can always withdraw your consent. You can do this by clicking on unsubscribe in any marketing email we send, or by getting in
          touch via the contact details in paragraph 1.
        </p>

        <p>
          If you withdraw your consent and we have no other legal basis for processing your data we will stop. If we do have another legal
          basis we will continue to do so, subject to your legal rights.
        </p>

        <p>Contractual Obligation:</p>

        <p>
          Processing your data is necessary for a contract you have with us, or because we have asked you to take specific steps before
          entering into that contract.
        </p>

        <p>Storing and Sharing Your Data</p>

        <p>
          Data security is very important to us and we take appropriate security measures to safeguard and secure your data which is
          collected via our website and services.
        </p>

        <p>
          We endeavour to keep all of your personal information in the European Economic Area (EEA). The EEA includes all EU Member States
          plus Norway, Iceland and Liechtenstein.
        </p>

        <p>
          In limited, and necessary, circumstances your information may be transferred outside of the EEA; this will only happen where it
          can’t be avoided. Where this does happen, we will put special protections in place. We will only move data to countries or
          organisations:
        </p>
        <ul>
          <li>Where the EU Commission has deemed their data protection measures to be adequate;</li>
          <li>
            Or under a contract which enforces the EU Commission approved “standard data protection clauses” which can be viewed at{' '}
            <a href="http://ec.europa.eu/justice/data-protection/international-transfers/transfer/index_en.htm">
              http://ec.europa.eu/justice/data-protection/international-transfers/transfer/index_en.htm
            </a>
            .
          </li>
        </ul>
        <p>We will never sell your personal data to a third party.</p>

        <p>We will transfer or disclose your personal data to any third party except:</p>

        <p>
          We may sometimes contract with trusted service providers to provide goods and services on our behalf. These may include payment
          processing, delivery of goods, search engine facilities, advertising, marketing and IT systems. This will sometimes necessitate
          the transfer of your personal data to those trusted service providers.
        </p>

        <p>
          Where we transfer your data to our trusted service providers we will have confirmed that they will apply data protection and
          security measures to the same standard we would. We will always impose contractual terms on all of our providers to ensure your
          data remains secure.
        </p>

        <p>
          In certain limited circumstances we may be legally required to share your personal data – for example where we are involved in
          legal proceedings, or where we are complying with a court order, regulatory requirement, or government department with appropriate
          legal authority to compel us to do so.
        </p>
      </PrivacySection>
      <PrivacySection heading="How Long We Keep Your Data">
        <p>
          We do not keep your personal data for any longer than it is necessary in light of the reason(s) for which it was first collected
          and for our obligation under other laws.
        </p>
        <p>
          We may need to keep your information to establish, bring or defend legal claims. We’ll therefore always keep your personal data
          for 7 years after the date it is no longer necessary for us to hold it.
        </p>
        <p>At the end of that period your data will be either deleted completely or anonymised.</p>
        <p>Exceptions to the above will be:</p>
        <ul>
          <li>Where you have exercised your right to have the information where it applies.</li>
          <li>Where the law requires us to keep your data for longer or delete it sooner.</li>
          <li>Where a legal claim is in progress – we’ll keep your data until that claim is concluded.</li>
        </ul>
      </PrivacySection>
      <PrivacySection heading="Cookies">
        <p>
          Like many other websites, ours uses cookies. Cookies are small pieces of information sent by an organisation to your computer or
          device and stored on your computer or device to allow a website to recognise you when you visit. They help us collect statistical
          data about your browsing actions and patterns but should not contain personal data (other than the cookie itself, which is defined
          as personal data under GDPR).
        </p>
        <p>All cookies used by and on our website are used in accordance with the current cookie law.</p>
        <p>
          Before cookies are placed on your computer or device you will be shown a pop-up requesting your consent to set such cookies as
          detailed below. By giving us your consent, you are allowing us to provide the best possible experience of our website. You may
          refuse consent to cookies; however, certain parts of our website may then not function correctly. You can alter your browser
          settings to refuse all cookies.
        </p>
        <p>There are four types of cookies:</p>
        <ul>
          <li>
            Strictly Necessary Cookies – These are essential to make a website work and provide features you’ve asked for. Generally, these
            are used to provide shopping baskets and similar. Without these cookies the website may not work as intended.
          </li>
          <li>
            Performance Cookies – These collect anonymous information about users for the purposes of tracking the performance of a website.
            Common uses include well-known analytics tools such as Google Analytics.
          </li>
          <li>
            Targeting/Advertising Cookies – These are similar to performance cookies. However, they are used to track users’ behaviour and
            that information is then used on a “per user” basis to advertise products/services on the basis of the behavioural information
            collected.
          </li>
          <li>
            Functionality Cookies – These are used to remember automatically the choices that users have made in order to improve their
            experience on the website; for example, selecting desired layout or language.
          </li>
        </ul>
        <p>The following services are in use on our website:</p>
        <ul>
          <li>Google Analytics</li>
          <li>Hotjar</li>
          <li>AddThis</li>
          <li>Hubspot</li>
          <li>Facebook Pixel</li>
          <li>YouTube</li>
          <li>LinkedIn</li>
          <li>Twitter</li>
        </ul>
        <p>Changes to Our Privacy Notice</p>
        <p>
          We may change this privacy notice from time to time (for example, if the law changes). Any changes will be immediately posted on
          our site. We recommend you check the privacy notice regularly to remain up to date.
        </p>
        <p>This privacy notice was last updated in August 2020.</p>
      </PrivacySection>
    </IndexLayout>
  )
}

export default PrivacyPolicy
